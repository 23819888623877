<template>
  <div class="p-3">
    <form @submit.prevent="sendCreateClinic">
      <div class="form-group mb-3">
        <label for="txt-clinic-name" class="robo-20-500 required" autofocus>{{
          $t("workspace_setting.lbl_we_name")[$orgModel]
        }}</label>
        <input
          type="text"
          v-model="params.name"
          class="form-control"
          id="txt-clinic-name"
          :placeholder="$t('workspace_setting.lbl_ws_name_plh')[$orgModel]"
          autocomplete="off"
        />
      </div>
      <div class="row mb-3 w-full">
        <div class="col-sm-6">
          <label class="robo-20-500 mb-1 txt-grey-900 required">{{
            $t("workspace_setting.lbl_org_name")
          }}</label>
        </div>

        <div
          class="bg-blue-gray txt-grey-700 fw-600 rounded-md ml-2 p-0 hodo-el"
        >
          <el-select
            class="w-full"
            v-model="params.org_id"
            default-first-option
            clearable
            filterable
            remote
            reserve-keyword
            allow-create
            :remote-method="handleFilterSearchSubOrg"
            :loading="loading"
            :placeholder="$t('workspace_setting.lbl_org_name')"
            @change="handleResetFilterSearchSubOrg"
          >
            <el-option
              v-for="item in orgsFilter"
              :key="item.id"
              :label="item.suborg_name || item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="form-group mb-3">
        <label for="tc" class="robo-20-500 required">{{$t('workspace_setting.lbl_org')}}</label>
        <input
          type="text"
          v-model="params.org_name "
          class="form-control"
          id="tc"
          :placeholder="$t('workspace_setting.lbl_org_name_plh')"
          autocomplete="off"
        />
      </div>-->
      <div class="form-group mb-3">
        <label for="txt-address" class="robo-20-500">{{
          $t("personal_settings.lbl_address")
        }}</label>
        <input
          type="text"
          v-model="params.address"
          class="form-control"
          id="txt-address"
          :placeholder="$t('workspace_setting.lbl_address_plh')"
          autocomplete="off"
        />
      </div>
      <div class="form-group mb-3">
        <label for="desc" class="robo-20-500">{{
          $t("workspace_setting.lbl_description")
        }}</label>
        <textarea
          id="desc"
          rows="3"
          v-model="params.desc_full"
          class="form-control"
        ></textarea>
      </div>
      <div class="mb-3 d-flex align-items-center">
        <input type="checkbox" v-model="accepted" class="mr-2" id="at" />
        <label for="at" class="mb-0 mr-1">{{
          $t("workspace_setting.lbl_agree_msg")
        }}</label>
        <a
          href="/HODOServicesAgreementWS/HODOWorkspaceServiceAgreementWorkspace.pdf"
          target="_blank"
          >{{ $t("workspace_setting.lbl_agrrement")[$orgModel] }}</a
        >
      </div>
      <div class="d-flex justify-content-between mt-3">
        <button
          type="submit"
          class="btn bg-pri bd-pri text-white"
          @submit.prevent="sendCreateClinic"
          :disabled="loading || !accepted || !params.name"
        >
          {{ $t("workspace_setting.btn_create_ws")[$orgModel] }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import appUtils from '../utils/appUtils'
import orgRole from '../constants/orgRole'
import { isNumber } from 'lodash'

export default {
  name: 'CreateClinic',
  data () {
    return {
      params: {
        name: '',
        address: '',
        org_name: '',
        desc_full: '',
        org_id: ''
      },
      accepted: false,
      loading: false,
      orgsFilter: [],
      listOrg: []
    }
  },
  mounted () {
    this.getCurrentOrg()
  },
  methods: {
    async sendCreateClinic () {
      try {
        let self = this
        self.loading = true
        const clonedParams = { ...self.params }

        if (isNumber(clonedParams.org_id)) {
          const orgInfo =
            self.orgsFilter.find((item) => item?.id === clonedParams.org_id) ||
            {}
          clonedParams.org_name = orgInfo.name

          const isOwnerOrAdmin = self.$user.members.some(
            (item) =>
              item?.org_id === clonedParams.org_id &&
              [orgRole.Admin, orgRole.Owner].includes(item?.role)
          )

          if (!isOwnerOrAdmin) {
            self.$toast.open({
              message: 'Bạn không có quyền tạo Khoa ở tổ chức này',
              type: 'warning'
            })
            self.loading = false
            return
          }
        } else {
          clonedParams.org_name = clonedParams.org_id
          delete clonedParams.org_id
        }

        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .postDoctorClinic(clonedParams)
        appUtils.setDoctorClinic(resp.data)
        setTimeout(() => {
          window.location.href = '/'
        }, 300)
        self.is_alert = true
        self.$emit('closeShowCreate')
      } catch (e) {
      } finally {
        self.loading = false
      }
    },
    closeShow () {
      let self = this
      self.$emit('closeShowCreate')
    },
    getCurrentOrg () {
      if (this.$user && this.$user?.members?.length > 0) {
        this.listOrg = this.orgsFilter = this.$user.members.map(
          (item) => item?.org || {}
        )
      } else {
        this.listOrg = []
      }
    },
    handleFilterSearchSubOrg (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.orgsFilter = this.listOrg.filter((item) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
          })
        }, 200)
      } else {
        this.orgsFilter = this.listOrg
      }
    },
    handleResetFilterSearchSubOrg () {
      this.orgsFilter = this.listOrg
    }
  }
}
</script>
<style scoped>
</style>
