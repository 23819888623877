<template>
  <div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t('lbl_bank_account_information') }}</h5>
        <span class="btn-close" @click="closeShow()"></span>
      </div>
      <div class="modal-body">
        <div>
          <form @submit.prevent="sendCreateBankInfo" method="POST">
            <div class="container-fluid">
              <div class="form-group mt-3">
                <label for="txt-bank-name" class="txt-label required" autofocus>{{ $t('lbl_bank') }}</label>
                <v-select
                  class="mt-2"
                  :options="getBankFilter()"
                  label="item_label"
                  v-model="selected_bank"
                  :placeholder="$t('lbl_enter_keyword')"
                  :filterable="false"
                  @search="searchBank"
                >
                  <div slot="no-options">Not found!</div>
                  <template v-slot:option="item">
                    <span
                      class="font-weight-bold"
                    >({{ item.shortName }} - {{ item.bankCode }}) {{is_lang_vn ? item.vn_name : item.en_name }}</span>
                  </template>
                </v-select>
              </div>
              <div class="form-group mt-3">
                <label
                  for="txt-bank-account"
                  class="txt-label required"
                >{{ $t('lbl_bank_account_number') }}</label>
                <input
                  type="text"
                  v-model="params.bank_number"
                  class="form-control mt-1 txt-blue04"
                  id="txt-bank-account"
                  :placeholder="$t('lbl_enter_bank_account_number')"
                  autocomplete="off"
                />
              </div>
              <div class="form-group mt-3">
                <label
                  for="txt-bank-number"
                  class="txt-label required"
                >{{ $t('lbl_account_holder') }}</label>
                <input
                  type="text"
                  v-model="params.bank_account"
                  class="form-control mt-1 txt-blue04"
                  id="txt-bank-number"
                  :placeholder="$t('lbl_enter_account_holder')"
                  autocomplete="off"
                />
              </div>
              <div class="form-group mt-3">
                <label for="txt-bank-branch" class="txt-label required">{{ $t('lbl_bank_branch') }}</label>
                <input
                  type="text"
                  v-model="params.bank_branch"
                  class="form-control mt-1 txt-blue04"
                  id="txt-bank-branch"
                  :placeholder="$t('lbl_enter_bank_branch')"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-footer text-center">
              <button
                type="submit"
                class="btn bg-pri bd-pri text-white w-200"
                @submit.prevent="sendCreateBankInfo"
              >{{ $t('lbl_save_info') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import appUtils from '../utils/appUtils'
export default {
  name: 'CreateBankAccount',
  components: { vSelect },
  data () {
    return {
      params: {
        clinic_id: 0,
        bank_name: '',
        bank_account: '',
        bank_number: '',
        bank_branch: ''
      },
      selected_bank: '',
      list_bank: [],
      is_lang_vn: true,
      key_search: '',
      clinic_id_now: 0,
      request_class: ''
    }
  },
  computed: {
    getClinicID () {
      try {
        return localStorage.getItem('global_wp_id')
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    let self = this
    self.params.clinic_id = self.getClinicID
    self.getBank()
    self.getRequestClass()
    self.is_lang_vn = this.getLanguage()
    window.$(document).ready(function () {
      window.$('#modal-create-bank').on('show.bs.modal', function (e) {
        self.onRefresh()
      })
    })
  },
  methods: {
    getRequestClass () {
      let self = this
      if (appUtils.isAdmin()) {
        self.request_class = 'AdminRequest'
      } else if (appUtils.isDoctor()) {
        self.request_class = 'DoctorRequest'
      }
    },
    onRefresh () {
      let self = this
      self.params.bank_name = ''
      self.params.bank_account = ''
      self.params.bank_number = ''
      self.params.bank_branch = ''
      self.selected_bank = ''
    },
    getBankFilter () {
      let self = this
      if (self.key_search) {
        return self.list_bank.filter(
          item =>
            item.en_name.toLowerCase().indexOf(self.key_search.toLowerCase()) >=
              0 ||
            item.vn_name.toLowerCase().indexOf(self.key_search.toLowerCase()) >=
              0 ||
            item.bankCode
              .toLowerCase()
              .indexOf(self.key_search.toLowerCase()) >= 0 ||
            item.shortName
              .toLowerCase()
              .indexOf(self.key_search.toLowerCase()) >= 0
        )
      } else {
        return self.list_bank
      }
    },
    searchBank (search) {
      let self = this
      self.key_search = search
    },
    async sendCreateBankInfo () {
      try {
        let self = this
        if (self.selected_bank) {
          self.params.bank_name = self.is_lang_vn
            ? self.selected_bank.vn_name +
              ' (' +
              self.selected_bank.shortName +
              ')'
            : self.selected_bank.en_name +
              ' (' +
              self.selected_bank.shortName +
              ')'
        }
        await self.$rf
          .getRequest(self.request_class)
          .postBankAccounts(self.params)
        self.$toast.open({
          message: 'Thêm thông tin tài khoản ngân hàng thành công',
          type: 'success'
        })
        self.$emit('closeShowCreate', false, true)
      } catch (e) {
        this.$emit('closeShowCreate', false, false)
      }
    },
    getLanguage () {
      var lang = localStorage.getItem('language')
      if (lang === 'vi') {
        return true
      }
      return false
    },
    closeShow () {
      let self = this
      self.$emit('closeShowCreate', false, false)
    },
    async getBank () {
      try {
        let self = this
        var result = await self.$rf.getRequest('AuthRequest').getBank()
        self.list_bank = result.data
        self.list_bank.map(x => {
          x.item_label = this.getLanguage()
            ? x.vn_name + ' (' + x.shortName + ')'
            : x.en_name + ' (' + x.shortName + ')'
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style scoped>
#txt-clinic-name:focus,
#txt-address:focus {
  outline: none;
}
.txt-label {
  font-weight: bold !important;
  font-size: 15px;
}
.txt-red {
  color: red;
}
.bound-chil {
  border: none;
  border-radius: 20px;
}
.bound-bound-title {
  background-color: #20419b;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.bound-title {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.btn-close {
  cursor: pointer;
}
.txt-blue04 {
  color: #20419b;
  font-weight: 500;
  font-size: 16px;
}
</style>