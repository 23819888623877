<template>
    <div class="text-center">
    	<img src="../../public/assets/images/img-thinking.svg" alt="" width="300">
        <p class="robo-20-400 text-center">{{$t('workspace_setting.lbl_create_or_join')[$orgModel]}}</p>
        <div class="text-center mb-3">
            <button type="button" class="w-75 btn bg-pri bd-pri text-white" @click="onOpenWorkspace()">{{$t('workspace_setting.lbl_create_ws')[$orgModel]}}</button>
        </div>
        <div class="text-center">
            <button type="button" class="w-75 btn bg-pri bd-pri text-white" @click="onOpenCodeInvites()">{{$t('workspace_setting.lbl_join_ws')[$orgModel]}}</button>
        </div>
    </div>
</template>
<script>
export default {
  'name': 'CreateAddOrSelect',
  data () {
    return {
      params: {
        'type': 'clinic',
        'code': ''
      }
    }
  },
  methods: {
    onOpenCodeInvites () {
      let self = this
      self.$emit('onOpenCodeInvites')
    },
    onOpenWorkspace () {
      let self = this
      self.$emit('showCreate')
    }
  }
}
</script>
<style scoped>
</style>