<template>
  <div class="p-3" v-loading="loading">
    <form
      autocomplete="off"
      novalidate="novalidate"
      @submit.prevent="handleSubmitCreateOrg"
    >
      <div class="mb-3">
        <label class="block mb-0.5 required required">{{
          $t("sign_up.input_text.org_name")
        }}</label>
        <div>
          <input
            v-model="form.name"
            :placeholder="$t('sign_up.input_text.org_name')"
            type="text"
            class="form-control form-input fs-16 fw-500 w-full"
          />
        </div>
        <small v-if="isErrorValid && !!errorFields.name" class="text-warning">{{
          errorFields.name
        }}</small>
      </div>
      <div class="mb-3">
        <label class="block mb-0.5 required">{{
          $t("form.title.address")
        }}</label>
        <div>
          <input
            v-model="form.address"
            :placeholder="$t('form.title.address')"
            type="text"
            class="form-control form-input fs-16 fw-500 w-full"
          />
        </div>
        <small
          v-if="isErrorValid && !!errorFields.address"
          class="text-warning"
          >{{ errorFields.address }}</small
        >
      </div>
      <div class="mb-3">
        <label class="block mb-0.5 required">{{
          $t("form.title.phone")
        }}</label>
        <div>
          <input
            v-model="form.phone"
            :placeholder="$t('form.title.phone')"
            type="text"
            class="form-control form-input fs-16 fw-500 w-full"
          />
        </div>
        <small
          v-if="isErrorValid && !!errorFields.phone"
          class="text-warning"
          >{{ errorFields.phone }}</small
        >
      </div>
      <div class="mb-3">
        <label class="block mb-0.5">{{ $t("form.title.website") }}</label>
        <div>
          <input
            v-model="form.website"
            :placeholder="$t('form.title.website')"
            type="text"
            class="form-control form-input fs-16 fw-500 w-full"
          />
        </div>
        <small
          v-if="isErrorValid && !!errorFields.website"
          class="text-warning"
          >{{ errorFields.website }}</small
        >
      </div>
      <div class="mb-3">
        <label class="block mb-0.5 required">{{
          $t("form.title.ein_code")
        }}</label>
        <div>
          <input
            v-model="form.ein"
            :placeholder="$t('form.title.ein_code')"
            type="text"
            class="form-control form-input fs-16 fw-500 w-full"
          />
        </div>
        <small v-if="isErrorValid && !!errorFields.ein" class="text-warning">{{
          errorFields.ein
        }}</small>
      </div>
      <div class="mb-3 d-flex align-items-center">
        <input type="checkbox" v-model="accepted" class="mr-2" id="at" />
        <label for="at" class="mb-0 mr-1">{{
          $t("workspace_setting.lbl_agree_msg")
        }}</label>
        <a
          href="/HODOServicesAgreementWS/HODOWorkspaceServiceAgreementWorkspace.pdf"
          target="_blank"
          >{{ $t("workspace_setting.lbl_agrrement")[$orgModel] }}</a
        >
      </div>
      <div class="d-flex justify-content-between mt-3">
        <button
          type="submit"
          class="btn bg-pri bd-pri text-white"
          @submit.prevent="handleSubmitCreateOrg"
          :disabled="loading || !accepted || !form.name"
        >
          {{ $t("blank_state.btn_create_org") }}
        </button>
        <button type="button" class="btn btn--ghost" @click="closeShow()">
          {{ $t("button.back") }}
        </button>
      </div>
    </form>

    <!-- <form class="hidden" @submit.prevent="sendCreateClinic">
      <div class="form-group mb-3">
        <label
          for="txt-clinic-name"
          class="robo-20-500 required"
          autofocus
        >{{$t('workspace_setting.lbl_we_name')[$orgModel]}}</label>
        <input
          type="text"
          v-model="params.name"
          class="form-control"
          id="txt-clinic-name"
          :placeholder="$t('workspace_setting.lbl_ws_name_plh')[$orgModel]"
          autocomplete="off"
        />
      </div>
      <div class="form-group mb-3">
        <label for="tc" class="robo-20-500 required">{{$t('workspace_setting.lbl_org')}}</label>
        <input
          type="text"
          v-model="params.org_name "
          class="form-control"
          id="tc"
          :placeholder="$t('workspace_setting.lbl_org_name_plh')"
          autocomplete="off"
        />
      </div>
      <div class="form-group mb-3">
        <label for="txt-address" class="robo-20-500">{{$t('personal_settings.lbl_address')}}</label>
        <input
          type="text"
          v-model="params.address"
          class="form-control"
          id="txt-address"
          :placeholder="$t('workspace_setting.lbl_address_plh')"
          autocomplete="off"
        />
      </div>
      <div class="form-group mb-3">
        <label for="desc" class="robo-20-500">{{$t('workspace_setting.lbl_description')}}</label>
        <textarea id="desc" rows="3" v-model="params.desc_full " class="form-control"></textarea>
      </div>
      <div class="mb-3 d-flex align-items-center">
        <input type="checkbox" v-model="accepted" class="mr-2" id="at" />
        <label for="at" class="mb-0 mr-1">{{$t('workspace_setting.lbl_agree_msg')}}</label>
        <a
          href="/HODOServicesAgreementWS/HODOWorkspaceServiceAgreementWorkspace.pdf"
          target="_blank"
        >{{ $t('workspace_setting.lbl_agrrement')[$orgModel] }}</a>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <button
          type="submit"
          class="btn bg-pri bd-pri text-white"
          @submit.prevent="sendCreateClinic"
          :disabled="loading ||!accepted || !params.name"
        >{{$t('workspace_setting.btn_create_ws')[$orgModel]}}</button>
      </div>
    </form>-->
  </div>
</template>
<script>
import appUtils from '../utils/appUtils'
import { mixinValidationForms } from '../utils/mixinValidationForms'

export default {
  name: 'CreateOrg',
  mixins: [mixinValidationForms],
  data () {
    const initialForm = {
      name: '',
      address: '',
      phone: '',
      website: '',
      ein: ''
    }
    return {
      params: {
        name: '',
        address: '',
        org_name: '',
        desc_full: ''
      },
      initialForm,
      form: { ...initialForm },
      ignoreFields: ['website'],
      accepted: false,
      loading: false
    }
  },
  beforeMount () {
    this.handleResetErrors(this.form, this.errorFields, this.ignoreFields)
    this.isErrorValid = false
  },
  methods: {
    async sendCreateClinic () {
      try {
        let self = this
        self.loading = true
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .postDoctorClinic(self.params)
        appUtils.setDoctorClinic(resp.data)
        setTimeout(() => {
          window.location.href = '/'
        }, 300)
        self.is_alert = true
        self.$emit('closeShowCreate')
      } catch (e) {
      } finally {
        self.loading = false
      }
    },
    async handleSubmitCreateOrg () {
      const self = this
      self.isErrorValid = self.handleResetErrors(
        self.form,
        self.errorFields,
        self.ignoreFields
      )

      try {
        self.isErrorValid = self.handleValidationForms(
          self.form,
          self.errorFields,
          self.ignoreFields
        )

        if (self.isErrorValid) {
          return
        }

        if (!self.$user) {
          self.$message({
            type: 'warning',
            message: self.$t('Không tìm thấy thông tin người dùng')
          })
          return
        }

        self.loading = true

        const params = {
          ...self.form
        }
        const resp = await self.$rf
          .getRequest('DoctorRequest')
          .createNewOrganization(params)

        if (!resp.data) return

        self.$message({
          type: 'success',
          message: self.$t('Bạn đã tạo thành công Tổ chức')
        })

        if (!self.$globalOrg) {
          appUtils.setGlobalOrg(resp.data?.Org)
        }
        await self.reCallAuthMe()
        self.closeShow()
      } catch (error) {
        console.log(error)
        self.loading = false
      }
    },
    closeShow () {
      let self = this
      self.form = { ...self.initialForm }
      self.accepted = false
      self.$emit('closeShowCreate')
    },
    async reCallAuthMe () {
      let resp = await this.$rf.getRequest('AuthRequest').me()
      if (resp) {
        appUtils.setLocalUser(resp.data)
      }
      return resp
    }
  }
}
</script>
<style scoped>
</style>
